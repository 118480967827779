.listingCard {
  border-radius: 8px !important;
  margin-right: auto;
  margin-top: 0em;
  width: 100%;
}

.listingCardCategory {
  border-radius: 8px !important;
  width: 100%;
  /* padding: 0.5rem; */
  margin-left: auto;
  margin-right: auto;
}

.newBadge {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #222222;
  color: white;
  border-radius: 5px;
  padding: 0rem 0.3rem;
  font-weight: 600;
  font-size: 12px;
}

.brand {
  cursor: pointer;
  color: var(--text-body);
  font-family: "Editorial";
  font-size: 10px;
  border-radius: 5px;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  margin-top: 4px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 2;
  text-transform: uppercase;
}
/* .brand:hover {
  border: 1px solid var(--primary-purple);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} */

.titleText {
  width: 100%;
  font-size: 25px;
  padding-top: 1rem;
  font-weight: 400;
  color: var(--primary-purple);
  text-align: center;
}

.dealBadge {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 2em;
  top: 1em;
  left: 1em;
  margin: auto;
  background-color: #ba4444 !important;
  border-radius: 0 2px 2px 0;
  padding: 6px 8px;
  color: white;
  font-size: 11px;
  font-weight: 400;
}

.viewedXTimes {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 2em;
  font-size: 12px;
  top: 0.5em;
  right: 0.5em;
  margin: auto;
  color: #222222;
  font-weight: 300;
  text-shadow: 0.5px 0.5px 1px white;
}

.viewedIcon {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  margin-right: 0.2rem;
  width: 20px;
}

.text {
  margin-top: 7px;
  font-size: 16px;
  margin-bottom: auto;
  margin-right: 0.1em;
}

.Link {
  text-decoration: none;
  color: black;
}

.Link:hover {
  text-decoration: none;
}

.postDate {
  margin: 0%;
  padding: 0%;
  color: gray;
  font-size: 13px;
  font-style: italic;
}

.retailPrice {
  color: var(--text-mute);
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 13px;
  letter-spacing: 0.02em;
  margin-bottom: 0rem;
}

.price {
  color: var(--text-main);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.025rem;
  margin-bottom: 0rem;
}

.priceRed {
  color: var(--text-main);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.025rem;
  margin-bottom: 0rem;
}

.carousel {
  overflow: hidden;
  position: relative;
  width: inherit;
  text-align: center;
  display: flex;
  /* object-fit: cover; */
  justify-content: center;
  background: var(--primary-bg);
  border-radius: 2px !important;
  height: 207px;
  /* padding: 0.5rem; */
  align-items: center;
}

.carousel:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.carouselPlaceholder {
  position: relative;
  width: inherit;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 240px;
  width: 100%;
  background-color: var(--primary-bg);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.cardBodyPlaceholder {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 7em;
  max-height: 7em;
  /* padding-top: 0.8rem;
	padding-left: 0.2rem; */
  padding-top: 1rem;
  background-color: white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.placeholderBadge {
  background-color: var(--primary-bg);
  width: 85%;
  height: 10px;
  margin-top: 1rem;
  border-radius: 8px;
  margin-right: auto;
  margin-left: auto;
}

.white {
  display: none;
}

.listingImg1 {
  /* height: 13.5em; */
  /* min-height: 13.5em; */
  /* max-height: 195.5px; */
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  transition: 0.2s ease;
}
.brandPrice {
  display: flex;
  flex-direction: ROW;

  justify-content: space-between;
}
.listingImg1:hover {
  transform: scale(1.05);
  transition: 0.2s ease;
}

.listingImg1Category {
  height: 13.5em;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 5px;
  z-index: 1;
}

.cardBody {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
/* 
.sold {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0rem;
  margin-top: 1rem;
} */

.sold {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tetradic-blue);
  text-align: center;
  color: white;
  font-weight: 600;
  opacity: 0.8;
  width: 100%;
}

/* Card Body */

.titleDiv {
  margin-top: auto;
  margin-bottom: auto;
}
.title {
  color: var(--text-main);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.025rem;
  line-height: 150%;
  margin-bottom: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Editorial";
  letter-spacing: 0.025rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.infoSection {
  display: flex;
  margin-bottom: auto;
  align-items: center;
}

.favorite {
  position: absolute;
  right: 5px;
  height: 25px;
  width: fit-content;
  max-width: 50px;
  z-index: 1;
  bottom: 5px;
}

@media (max-width: 600px) {
  .listingCard {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .carousel {
    padding: 0.5rem;
  }

  .title {
    font-size: 14px;
  }

  .listingImg1 {
    max-height: 9em;
  }

  .carouselPlaceholder {
    max-height: 9em;
    width: 100% !important;
  }

  .titleText {
    padding-top: 0.5rem;
    font-size: 20px;
  }

  .price {
    font-size: 14px;
  }

  .priceRed {
    font-size: 14px;
  }

  .retailPrice {
    font-size: 11px;
  }
}

@keyframes wobble {
  25% {
    transform: rotate(2deg);
    transform: scale(1.5);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
